@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700;800;900&display=swap");

@font-face {
  font-family: font-icons;
  src: url("./Images/icon.ttf");
}
.icon {
  font-family: font-icons;
}
body {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif;
  color: #ddd;
  background-color: #353f59;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}
.drop {
  width: 10px !important;
}
.drop .mbox {
  position: absolute;
  background: #11ae54;
  width: 150px;
  max-height: 0px;
  margin-left: -140px;
  margin-top: -4px;
  border-radius: 6px;
  padding-left: 10px;
  overflow: hidden !important;
  transition: all 0.3s !important;
  box-shadow: 4px 4px 8px 1px rgba(0, 0, 0, 0.4);
}

.mbox a {
  display: block;
  width: 175px !important;
  height: 60px;
  line-height: 60px;
  padding-left: 10px;
  float: left;
}
.bb {
  border-bottom: 1px solid #ddd;
}
.mbox a:hover {
  color: #fff;
  font-weight: 600;
}
.drop:hover .mbox {
  display: block;
  height: auto;
  max-height: 175px;
}

a {
  color: #ddd;
  text-decoration: none;
}
a:hover {
  color: #fff;
}

small {
  font-size: 12px;
  font-weight: 300;
}
small a {
  color: #f1f4fa;
  text-decoration: none;
}

small a:hover {
  color: #395b92;
}

.dot {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-left: 5%;
  border-radius: 50%;
}

input[type="text"] {
  height: 30px;
  width: 86%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #4d5467;
  border-radius: 4px;
  line-height: 30px;
  color: #ddd;
  background-color: #252b3b;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
input[type="email"] {
  height: 30px;
  width: 86%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #4d5467;
  border-radius: 4px;
  line-height: 30px;
  color: #ddd;
  background-color: #252b3b;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
input[type="password"] {
  height: 30px;
  width: 86%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #4d5467;
  border-radius: 4px;
  line-height: 30px;
  color: #ddd;
  background-color: #252b3b;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
input[type="number"] {
  height: 30px;
  width: 86%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #4d5467;
  border-radius: 4px;
  line-height: 30px;
  color: #ddd;
  background-color: #252b3b;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-appearance: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding-left: 10px;
  border-right-width: 15px;
  border-color: #242a3d;
  font-size: 13px;
  color: #eee;
  background-color: #242a3d;
  width: 300px;
  height: 40px;
  outline: none;
}

.license {
  width: 96%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #4d5467;
  border-radius: 4px;
  line-height: 30px;
  color: #ddd;
  background-color: #252b3b;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-appearance: none;
}

.innerbox {
  width: 94%;
  max-height: 300px;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 3%;
  display: block;
  float: left;
}

input:focus {
  border: 1px solid #11ae54 !important;
  outline: none !important;
}

button {
  outline: none;
}

.header {
  position: fixed;
  height: 50px;
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  padding-bottom: 0px;
  background-color: #252b3b;
  border-bottom: 0px solid #2e353f;
  box-shadow: 1px 1px 8px 1px rgba(46, 53, 63, 0.3);
  z-index: 9;
}
.logo {
  height: 50px;
  width: 110px;
  float: left;
  text-align: right;
  color: #ddd;
  font-weight: 500;
  font-size: 22px;
  line-height: 40px;
}
.logo img {
  height: 75px;
  width: 75px;
  float: left;
  padding: 0px;
  margin-right: -85px !important;
  margin-top: -18px;
}

.header_links {
  height: 40px;
  width: auto;
  float: right;
  padding-left: 10px;
  padding-right: 0px;
}

.link {
  height: 38px;
  line-height: 38px;
  color: #ddd;
  font-size: 12px;
  text-decoration: none;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 4px;
  margin-left: 4px;
  float: left;
  display: block;
  font-weight: 400;
  border: 1px solid #252b3b;
  border-radius: 4px;
  transition: all 0.3s;
}
.link:hover {
  text-shadow: 0px 0px 1px #2e353f;
  background-color: #11ae54;
  color: #fff;
}

.link-input {
  background: #242a3d;
  height: 18px;
  color: #ddd;
  font-size: 12px;
  text-decoration: none;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 2%;
  float: left;
  display: block;
  font-weight: 400;
  border: 1px solid green;
  border-radius: 4px;
  transition: all 0.3s;
}

.link-input::placeholder {
  color: #ddd;
}

.customerinfo {
  width: 132px !important;
}

.customerinfocontent {
  margin-left: -17px !important;
  margin-top: -2px !important;
}

.btn {
  display: block;
  background-color: #11ae54;
  width: auto;
  color: #fff;
  height: 38px;
  line-height: 38px;
  font-size: 12px;
  text-decoration: none;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
  text-align: center;
  float: left;
  border: 1px solid #11ae54;
  cursor: pointer;

  transition: all 0.3s;
}
.btn:hover {
  border: 1px solid #11ae54;
  color: #ddd;
  background-color: #252b3b;
  font-weight: 400;
}

.btnmini {
  display: block;
  width: auto;
  color: #ddd;
  height: 26px;
  line-height: 24px;
  font-size: 11px;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  text-align: center;
  float: left;
  cursor: pointer;
  border: 1px solid #11ae54;
  background-color: #252b3b;
  transition: all 0.3s;
}
.btnmini:hover {
  border: 1px solid #11ae54;
  color: #fff;
  background-color: #11ae54;
  font-weight: 300;
}
.footer {
  position: fixed;
  bottom: 0px;
  height: 20px;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5px;
  padding-bottom: 10px;
  background-color: #252b3b;
  color: #ddd;
  text-align: center;
  float: left;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 30%;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.deletebutton {
  display: inline-block;
  background-color: #f14e4e;
  padding: 0.3em 1.2em;
  margin: 2% 0;
  border: 0.16em solid rgba(255, 255, 255, 0);
  border-radius: 2em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #ffffff;
  text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
  text-align: center;
  transition: all 0.2s;
  cursor: pointer;
}
.deletebutton:hover {
  border-color: rgba(255, 255, 255, 1);
}

.container {
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

.half {
  display: block;
  width: 48%;
  margin: 1%;
  padding-top: 20px;
  padding-bottom: 20px;
  float: left;
}

.login {
  display: block;
  text-align: center;
  width: 40%;
  padding: 30px;
  padding-bottom: 50px;
  border-radius: 5px;
  border: 1px solid #252b3b;
  float: right;
  margin-top: 80px;
  margin-bottom: 40px;
  background-color: #2d354c;
}

.googlelogin {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #2d354c;
}

.googlelogin p {
  font-size: large;
  font-weight: bold;
}

.addcustomer {
  display: block;
  text-align: center;
  width: 40%;
  padding: 30px;
  padding-bottom: 50px;
  border-radius: 5px;
  border: 1px solid #252b3b;
  margin-top: 100px;
  margin-bottom: 40px;
  margin-left: 250px;
  background-color: #2d354c;
}

.selectlicense {
  display: block;
  text-align: center;
  width: 40%;
  padding: 2%;
  padding-bottom: 3%;
  border-radius: 1%;
  border: 1px solid #252b3b;
  margin-top: 7%;
  margin-bottom: 5%;
  margin-left: 25%;
  background-color: #2d354c;
}

.box {
  overflow: auto;
  width: 100%;
  max-height: 300px;
  border-radius: 5px;
  border: 1px solid #252b3b;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #2d354c;
}

table {
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
  padding-left: 20px;
  border-radius: 5px;
  font-size: 12px;
}
th {
  border-bottom: 1px solid #11ae54;
  text-align: left;
  min-width: 120px;
}

table input {
  margin-bottom: 3px;
  margin-top: 3px;
}
td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"],
  input[type="radio"] {
    --active: #11ae54;
    --active-inner: #fff;
    --focus: 2px rgba(17, 174, 84, 0.3);
    --border: #11ae54;
    --border-hover: #11ae54;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    -webkit-transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  input[type="checkbox"]:after,
  input[type="radio"]:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    -webkit-transition: opacity var(--d-o, 0.2s),
      -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
    transition: opacity var(--d-o, 0.2s),
      -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s),
      -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
  }
  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
  input[type="checkbox"]:disabled,
  input[type="radio"]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type="checkbox"]:disabled:checked,
  input[type="radio"]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type="checkbox"]:disabled + label,
  input[type="radio"]:disabled + label {
    cursor: not-allowed;
  }
  input[type="checkbox"]:hover:not(:checked):not(:disabled),
  input[type="radio"]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type="checkbox"]:focus,
  input[type="radio"]:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type="checkbox"]:not(.switch),
  input[type="radio"]:not(.switch) {
    width: 21px;
  }
  input[type="checkbox"]:not(.switch):after,
  input[type="radio"]:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type="checkbox"]:not(.switch):checked,
  input[type="radio"]:not(.switch):checked {
    --o: 1;
  }
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }

  input[type="checkbox"]:not(.switch) {
    border-radius: 7px;
  }
  input[type="checkbox"]:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    -webkit-transform: rotate(var(--r, 20deg));
    transform: rotate(var(--r, 20deg));
  }
  input[type="checkbox"]:not(.switch):checked {
    --r: 43deg;
  }
  input[type="checkbox"].switch {
    width: 38px;
    border-radius: 11px;
  }
  input[type="checkbox"].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: var(--ab, var(--border));
    -webkit-transform: translateX(var(--x, 0));
    transform: translateX(var(--x, 0));
  }
  input[type="checkbox"].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type="checkbox"].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }

  input[type="radio"] {
    border-radius: 50%;
  }
  input[type="radio"]:after {
    width: 18px !important;
    height: 18px !important;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    -webkit-transform: scale(var(--s, 0.7));
    transform: scale(var(--s, 0.7));
  }
  input[type="radio"]:checked {
    --s: 0.5;
  }
}
input[type="date"] {
  height: 30px;
  width: 86%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #4d5467;
  border-radius: 4px;
  line-height: 30px;
  color: #ddd;
  background-color: #252b3b;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("./Images/cal.svg") no-repeat;
  width: 16px;
  height: 16px;
  border-width: thin;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #252b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #111;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
}

.popup {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  z-index: 500;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  /*    filter: blur(4px);*/
  backdrop-filter: blur(4px);
}

.innerpopup {
  position: absolute;
  z-index: 600;
  max-height: 400px;
  overflow: auto;
  top: 50%;
  margin-top: -200px;
  left: 50%;
  width: 600px;
  margin-left: -300px;
  background-color: #2d354c;

  box-shadow: 1px 1px 20px 2px rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  padding: 20px;
}

.pophead {
  width: 100%;
  height: 30px;
  font-weight: 600;
  font-size: 16px;
  color: #11ae54;
  margin-top: -20px;
  line-height: 30px;
  background-color: #252b3b;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -20px;
  margin-bottom: 20px;
}

.pophead button {
  float: right;
  border: none;
  outline: none;
  padding: 4px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.6);
  width: 40px;
  color: #ccc;
}
.pophead button:hover {
  background-color: crimson;
}

@media only screen and (max-width: 768px) {
  .popup {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    z-index: 500;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    /* filter: blur(4px); */
    backdrop-filter: blur(4px);
  }

  .innerpopup {
    position: static;
    z-index: 600;
    max-height: 400px;
    overflow: auto;

    width: 300px;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 80px !important;
    background-color: #2d354c;

    box-shadow: 1px 1px 20px 2px rgba(0, 0, 0, 0.6);
    border-radius: 4px;
  }

  .pophead {
    width: 100%;
    height: 30px;
    font-weight: 600;
    font-size: 16px;
    color: #11ae54;
    margin-top: -20px;
    line-height: 30px;
    background-color: #252b3b;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
    margin-bottom: 20px;
  }

  .pophead button {
    float: right;
    border: none;
    outline: none;
    padding: 4px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.6);
    width: 40px;
    color: #ccc;
  }
  .pophead button:hover {
    background-color: crimson;
  }

  .header {
    position: fixed;
    height: 50px;
    width: 92%;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 10px;
    padding-bottom: 0px;
    background-color: #252b3b;
    border-bottom: 0px solid #2e353f;
    box-shadow: 1px 1px 8px 1px rgba(46, 53, 63, 0.3);
    z-index: 9;
  }

  .select {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 12px;
    color: #eee;
    width: 100%;
    height: 35px;
  }

  .container {
    width: 92%;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .hide {
    display: none !important;
  }

  .half {
    display: block;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    float: left;
  }

  .login {
    display: block;
    width: 86%;
    padding-top: 30px;
    padding-bottom: 50px;
    border-radius: 5px;
    border: 1px solid #252b3b;
    float: left;
    margin-top: 20px;
    margin-bottom: 40px;
    background-color: #2d354c;
  }
}
